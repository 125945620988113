import React, { useState, useEffect } from 'react';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/pages/container/container"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const AboutUsPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {

  return (
    <Layout>
      <Seo title="About Us- Transcend Service"/>
        <section id="About-us">
            <Container>
                <div className="col-lg-12 text-center mt-5">
                    <h1 className="text-white mt-5">About Us Page</h1>
                </div>
            </Container>
        </section>
    </Layout>
  )
}

export default AboutUsPage
